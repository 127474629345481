<template>
  <div class="container">
    <div class="main">
      <div class="table-box">
        <h4>中考体育</h4>
        <table border="1" cellspacing="0" rules="all">
          <tr>
            <th>中考过程性考核项目</th>
            <th>体育出勤率考核</th>
            <th>学生体质检测</th>
            <th>专项运动技能考核</th>
            <th>总计</th>
          </tr>
          <tr>
            <td>成绩</td>
            <td>{{ total.attendRt }}</td>
            <td>{{ total.healthTest }}</td>
            <td>{{ total.sportSkill }}</td>
            <td>{{ total.all }}</td>
          </tr>
        </table>
      </div>

      <div class="more table-box">
		  <div class="record-title">
			  <h4>相关记录</h4>
			  <a :href="scoreRuleUrl" style="font-size: 12px;color: #666;" target="_blank">中考体育计分规则</a>
		  </div>
        <el-row type="flex" justify="space-between" class="more-tit more-tittop">
          <el-col :span="12">
            <h5>体育出勤率</h5>
          </el-col>
        </el-row>
        <table class="table" border="1" cellspacing="0" rules="all">
        	<tr>
        		<th>序号</th>
        		<th>学期</th>
        		<th>体育出勤率</th>
        		<th>中考得分</th>
        	</tr>
        	<tr v-for="(item, idx) in attendRt" :key="idx" v-if="attendRt.length > 0">
        		<td>{{ idx + 1 }}</td>
        		<td>{{ item.termName }}</td>
        		<td>{{ item.termRatio }}</td>
        		<td>{{ item.termScore }}</td>
        	</tr>
			<tr v-else>
				<td colspan="4">暂无数据</td>
			</tr>
        	<tr>
        		<td colspan="3">总计</td>
        		<td>{{ total.attendRt }}</td>
        	</tr>
        </table>
        
		<el-row type="flex" justify="space-between" class="more-tit more-tittop">
		  <el-col :span="12">
		    <h5>学生体质健康检测</h5>
		  </el-col>
		</el-row>
        <table class="table" border="1" cellspacing="0" rules="all">
        	<tr>
        		<th>序号</th>
        		<th>学期</th>
        		<th>体质检测成绩</th>
        		<th>中考得分</th>
        	</tr>
        	<tr v-for="(item, idx) in healthTest" :key="idx" v-if="healthTest.length > 0">
        		<td>{{ idx + 1 }}</td>
        		<td>{{ item.term }}</td>
        		<td>{{ item.totalGradesScore }}</td>
        		<td>{{ item.termScore }}</td>
        	</tr>
			<tr v-else>
				<td colspan="4">暂无数据</td>
			</tr>
        	<tr>
        		<td colspan="3">总计</td>
        		<td>{{ total.healthTest }}</td>
        	</tr>
        </table>
        
		<el-row type="flex" justify="space-between" class="more-tit more-tittop">
		  <el-col :span="12">
		    <h5>专项运动技能考核</h5>
		  </el-col>
		</el-row>
        <table class="table skill-score-table" border="1" cellspacing="0" rules="all">
        	<tr>
        		<th>序号</th>
        		<th class="term">学期</th>
        		<th>项目1</th>
        		<th>项目1成绩</th>
        		<th>项目2</th>
				<th>项目2成绩</th>
				<th>中考得分</th>
        	</tr>
        	<tr v-for="(item, idx) in sportSkill" :key="idx" v-if="sportSkill.length > 0">
        		<td>{{ idx + 1 }}</td>
        		<td>{{ item.termName }}</td>
        		<td>{{ item.projectOne }}</td>
        		<td>{{ item.projectOneScore }}</td>
        		<td>{{ item.projectTwo }}</td>
        		<td>{{ item.projectTwoScore }}</td>
				<td>{{ item.midExamScore }}</td>
        	</tr>
			<tr v-else>
				<td colspan="6">暂无数据</td>
			</tr>
        	<tr>
        		<td colspan="6">总计</td>
        		<td>{{ total.sportSkill }}</td>
        	</tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { stuPhyMidExamDetail } from "@/api/teacher/quality/health"
export default {
	data() {
		return {
			queryForm: {
				year: ''
			},
			scoreRuleUrl: `${process.env.VUE_APP_API}/shida-user-biz/static/河北省初中学业水平体育与健康科目考试方案及计分规则.pdf`,
			attendRt: [],
			healthTest: [],
			sportSkill: [],
			total: {
				attendRt: 0,
				healthTest: 0,
				sportSkill: 0,
				all: 0
			}
		}
	},
	created() {
		this.getData()
	},
	methods: {
		// 所有数据
		getData() {
			stuPhyMidExamDetail({
				userId: this.$store.getters.userId,
				year: ''
			}).then(res => {
				let d = res.data
				// 明细
				this.attendRt = d.sportattends
				this.healthTest = d.physiqueUsers
				this.sportSkill = d.specialSports
				// 合计分数
				this.total.attendRt = d.sportScore
				this.total.healthTest = d.phyScore
				this.total.sportSkill = d.sportSkillScore
				this.total.all = d.totalScore
			})
		}
	}
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/studentResult.scss";
.container {
  .user-nav {
    padding: 10px 12px;
  }
  .skill-score-table {
	  th, td {
		  width: 13% !important;
	  }
	  .term {
		  width: 22% !important;
	  }
  }
}
</style>